<template>
    <div>
        <div v-if="!isLoading" class=" bg-gray-100 overflow-hidden h-full">
            <div
                class="m-8 space-y-6 sm:px-6 lg:px-0 lg:col-span-9"
                :class="{ 'opacity-50': toggleModal }"
            >
                <section aria-labelledby="payment_details_heading">
                    <div class="sm:overflow-hidden">
                        <div
                            class="bg-white shadow rounded-2xl py-6 px-4 sm:p-6"
                        >
                            <div>
                                <div class="flex items-center justify-between">
                                    <div class="flex items-center">
                                        <h2
                                            id="payment_details_heading"
                                            class="lg:text-xl text-lg text-left leading-6 font-bold text-gray-900 mr-2"
                                        >
                                            {{ $t("billing.remaining_credit") }}
                                        </h2>
                                        <div
                                            class="text-right font-bold text-2xl lg:text-3xl"
                                        >
                                            {{ data.credit }}
                                        </div>
                                    </div>
                                    <button
                                        v-if="this.user.role !== 1"
                                        :disabled="makeTopUpButton"
                                        @click="makeTopUp"
                                        class="
                                                           bg-black rounded-md text-center text-white py-3 px-6 lg:px-10 transition duration-500 ease-in-out"
                                        :class="{
                                            'opacity-30': makeTopUpButton
                                        }"
                                    >
                                        {{ $t("billing.make_topup") }}
                                    </button>
                                </div>
                                <div class="flex justify-between items-center">
                                    <div
                                        v-if="
                                            !isLoading &&
                                                data.topup_list.length > 0
                                        "
                                        class="text-left lg:ml-4 text-xs lg:text-sm text-gray-500"
                                    >
                                        {{ $t("billing.last_top_up1") }}
                                        {{
                                            $i18n.locale === "en"
                                                ? data.topup_list[0].topup_amt
                                                : data.topup_list[0]
                                                      .payment_date
                                        }}
                                        {{ $t("billing.last_top_up2") }}
                                        {{
                                            $i18n.locale === "en"
                                                ? data.topup_list[0]
                                                      .payment_date
                                                : data.topup_list[0].topup_amt
                                        }}
                                    </div>
                                    <div></div>
                                </div>
                            </div>

                            <div class="relative">
                                <div class="flex items-baseline">
                                    <div class="mt-20  flex items-center">
                                        <div
                                            class="text-left text-sm text-gray-500"
                                        >
                                            {{ $t("billing.threshold") }}
                                            <span
                                                class="text-2xl font-medium text-black"
                                                >{{ data.amt_alert }}</span
                                            >
                                        </div>
                                    </div>
                                </div>
                                <div class="flex items-center mt-1">
                                    <input
                                        :readonly="this.user.role === 1"
                                        ref="threshold"
                                        class="pl-3 block border border-gray-300
                                rounded-md shadow-sm mr-5 h-auto w-24 py-1
                                focus:outline-none focus:ring-gray-900
                                focus:border-gray-900 sm:text-sm"
                                        placeholder="XXXX.XX"
                                        v-model="newThreshold"
                                        @keyup="
                                            isNaN($event.target.value)
                                                ? (invalidNum = true)
                                                : (invalidNum = false)
                                        "
                                    />
                                    <button
                                        v-if="this.user.role !== 1"
                                        :disabled="invalidNum"
                                        @click="updateThreshold(newThreshold)"
                                        class="rounded-md text-white py-1 px-3"
                                        :class="{
                                            'bg-black':
                                                !thresholdButton || !invalidNum,
                                            'opacity-30': thresholdButton,
                                            'bg-gray-400 cursor-default': invalidNum
                                        }"
                                    >
                                        {{ $t("button.update") }}
                                    </button>
                                </div>
                                <div
                                    v-if="invalidNum"
                                    class="text-xs text-left mt-1 text-red-600"
                                >
                                    {{ $t("billing.invalid_num") }}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div class="block lg:flex lg:justify-between">
                    <section
                        class="w-full"
                        :class="{ 'lg:w-halfGap': this.user.role !== 1 }"
                        aria-labelledby="billing_history_heading"
                    >
                        <div
                            class="bg-white rounded-t-3xl pt-7 shadow overflow-hidden"
                        >
                            <div class="pt-4 flex justify-between">
                                <h2
                                    class="max-w-6xl ml-4 lg:ml-0 lg:-mx-5 text-left text-xl leading-6 font-bold text-gray-900 sm:px-6 lg:px-8"
                                >
                                    {{ $t("billing.recent_top_up") }}
                                </h2>
                                <button
                                    type="button"
                                    class="mr-5 rounded-md text-cyan-700 hover:text-cyan-900"
                                    @click="
                                        this.$router.push({
                                            name: 'PaymentRecords'
                                        })
                                    "
                                >
                                    {{ $t("billing.view_all") }}
                                </button>
                            </div>
                            <div class="mt-6 flex flex-col">
                                <div
                                    class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8"
                                >
                                    <div
                                        class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8"
                                    >
                                        <div
                                            class="overflow-x-scroll border-t border-b border-gray-200"
                                        >
                                            <table
                                                class="table-fixed min-w-full divide-y divide-gray-200"
                                            >
                                                <thead>
                                                    <tr>
                                                        <th
                                                            class="w-1/8 pl-4 py-5 bg-gray-50 text-left text-sm border-r font-medium text-gray-500 tracking-wider"
                                                        >
                                                            {{
                                                                $t(
                                                                    "billing.payment_date"
                                                                )
                                                            }}
                                                        </th>
                                                        <th
                                                            class="w-1/8 pl-4 py-5 bg-gray-50 text-left text-sm border-r font-medium text-gray-500 tracking-wider"
                                                        >
                                                            {{
                                                                $t(
                                                                    "billing.amount"
                                                                )
                                                            }}
                                                        </th>
                                                        <th
                                                            v-if="
                                                                this.user
                                                                    .role !== 1
                                                            "
                                                            class="w-1/8 pl-4 py-5 bg-gray-50 text-left text-sm font-medium text-gray-500 tracking-wider"
                                                        >
                                                            {{
                                                                $t(
                                                                    "billing.receipt"
                                                                )
                                                            }}
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody
                                                    v-if="!isLoading"
                                                    class="bg-white divide-y divide-gray-200"
                                                >
                                                    <tr
                                                        class="bg-white text-left font-medium text-tiny"
                                                        v-for="(record,
                                                        index) in data.topup_list"
                                                        :key="index"
                                                    >
                                                        <td
                                                            class="border-r pl-4 py-3"
                                                        >
                                                            {{
                                                                record.payment_date
                                                            }}
                                                        </td>
                                                        <td
                                                            class="border-r pl-4 py-3"
                                                        >
                                                            {{
                                                                record.topup_amt
                                                            }}
                                                        </td>
                                                        <td
                                                            v-if="
                                                                this.user
                                                                    .role !== 1
                                                            "
                                                            class="border-r pl-4 py-3"
                                                        >
                                                            <button
                                                                v-if="
                                                                    record.state ===
                                                                        1
                                                                "
                                                                @click="
                                                                    getInvoiceContent(
                                                                        record.id
                                                                    )
                                                                "
                                                                class="text-cyan-600 hover:text-cyan-900"
                                                            >
                                                                {{
                                                                    $t(
                                                                        "billing.download"
                                                                    )
                                                                }}
                                                            </button>
                                                            <span
                                                                v-if="
                                                                    record.state ===
                                                                        0
                                                                "
                                                                >{{
                                                                    $t(
                                                                        "billing.pending"
                                                                    )
                                                                }}</span
                                                            >
                                                            <span
                                                                v-if="
                                                                    record.state ===
                                                                        2
                                                                "
                                                                >{{
                                                                    $t(
                                                                        "billing.rejected"
                                                                    )
                                                                }}</span
                                                            >
                                                        </td>
                                                    </tr>

                                                    <!-- More items... -->
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="bg-white pb-12 rounded-b-3xl"></div>
                    </section>
                    <section
                        v-if="this.user.role !== 1"
                        class="relative mt-6 lg:mt-0 lg:w-halfGap bg-white mb-auto rounded-3xl pt-7 pb-10 shadow overflow-hidden"
                    >
                        <div class=" pt-4 flex justify-between">
                            <h2
                                v-if="!addCard"
                                class="max-w-6xl lg:-mx-5 ml-2 lg:ml-0 text-left text-xl leading-6 font-bold text-gray-900 sm:px-6 lg:px-8"
                            >
                                {{ $t("billing.payment_methods") }}
                            </h2>
                            <h2
                                v-if="addCard"
                                class="max-w-6xl ml-4 lg:-mx-5 text-left text-xl leading-6 font-bold text-gray-900 sm:px-6 lg:px-8"
                            >
                                {{ $t("billing.new_payment") }}
                            </h2>
                            <button
                                v-if="!addCard"
                                type="button"
                                class="mr-5 text-cyan-700 hover:text-cyan-900 focus:outline-none"
                                @click="addCardBtn"
                            >
                                {{ $t("billing.add_card") }}
                            </button>
                        </div>
                        <div v-show="!addCard" class="relative px-8 pb-8">
                            <div v-for="(card, index) in bankInfo" :key="index">
                                <div
                                    class="my-5 desktop:my-10"
                                    v-if="card.type === 'stripe'"
                                >
                                    <div
                                        class="flex items-center justify-between text-lg font-medium"
                                    >
                                        <div class="flex items-center">
                                            <img
                                                class="w-14 desktop:w-16"
                                                :src="card.payment_icon"
                                                alt="card logo"
                                            />
                                            <span class="ml-5 desktop:ml-10"
                                                >{{ card.brand }} ....{{
                                                    card.last4
                                                }}</span
                                            >
                                        </div>
                                        <div class="flex">
                                            <button
                                                type="button"
                                                class="mr-5 text-cyan-700 hover:text-cyan-900 focus:outline-none"
                                                @click="
                                                    removeCard(card.card_id)
                                                "
                                            >
                                                {{ $t("button.remove") }}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-show="addCard" class="relative p-8">
                            <label class="flex ml-3 mb-5" for="card-number">
                                {{ $t("billing.cardNum") }}
                            </label>
                            <div
                                class="border pl-3 py-1 mb-10"
                                ref="cardNum"
                                id="card-number"
                            ></div>
                            <label class="flex ml-3 mb-5" for="card-name">
                                {{ $t("billing.cardName") }}
                            </label>
                            <input
                                class="border pl-3 focus:outline-none w-full mb-10"
                                ref="card_name"
                                id="card-name"
                            />
                            <div class="flex justify-between">
                                <div class="w-halfGap">
                                    <label
                                        class="flex ml-3 mb-5"
                                        for="card-expiry"
                                    >
                                        {{ $t("billing.cardExpiry") }}
                                    </label>
                                    <div
                                        class="border pl-3 py-1"
                                        id="card-expiry"
                                    ></div>
                                </div>
                                <div class="w-halfGap">
                                    <label
                                        class="flex ml-3 mb-5"
                                        for="card-cvc"
                                    >
                                        {{ $t("billing.cardCVV") }}
                                    </label>
                                    <div
                                        class="border pl-3 py-1"
                                        id="card-cvc"
                                    ></div>
                                </div>
                            </div>
                        </div>
                        <div class="flex justify-end mr-8">
                            <button
                                v-if="addCard"
                                @click="discardCard"
                                class="bg-black text-white py-1 mx-4 px-4 desktop:py-2 desktop:px-8 rounded-md"
                            >
                                {{ $t("button.discard") }}
                            </button>
                            <button
                                v-if="addCard"
                                @click="saveCard"
                                class="bg-black text-white py-1 px-4 desktop:py-2 desktop:px-8 rounded-md"
                            >
                                {{ $t("button.save") }}
                            </button>
                        </div>
                    </section>
                </div>
            </div>
            <div
                v-if="toggleModal"
                class="absolute z-10 inset-0 mt-32 desktop:mt-0 flex justify-center items-center"
            >
                <div
                    class="relative h-full lg:h-auto mx-auto w-11/12 lg:w-5/12 desktop:w-1/3"
                >
                    <div class="bg-white rounded-xl shadow-2xl px-8 py-4">
                        <div
                            class="flex -mx-8 -mt-8 px-4 py-4 bg-cyan-900 text-left px-3 rounded-xl"
                        >
                            <button
                                @click="closeModal"
                                class="text-white text-2xl"
                            >
                                ×
                            </button>
                            <p class="ml-3 text-white text-2xl">
                                {{ $t("billing.make_a_topup") }}
                            </p>
                        </div>
                        <div v-for="(card, i) in this.bankInfo" :key="i">
                            <div
                                class="border-b w-full px-5 py-3 flex text-left"
                                v-if="card.type === 'stripe'"
                            >
                                <span
                                    v-if="i === activeItem && paymentType === 1"
                                    class="-ml-7 px-2"
                                    >✓</span
                                >
                                <button
                                    :disabled="disableSwitch"
                                    class="w-full text-left focus:outline-none"
                                    @click="selectItem(i, card.card_id)"
                                >
                                    {{ card.brand }} ...{{ card.last4 }}
                                </button>
                            </div>
                        </div>
                        <div
                            class="border-b px-5 py-3 text-left focus:bg-red-100"
                        >
                            <span v-if="paymentType === 2" class="-ml-7 px-2"
                                >✓</span
                            >
                            <button
                                class="focus:outline-none"
                                @click="paymentType = 2"
                            >
                                {{ $t("billing.bank_transfer") }}
                            </button>
                        </div>
                        <div class="w-full">
                            <div v-if="!isBankSelected">
                                <div
                                    class="text-left pt-3 text-sm text-gray-500"
                                >
                                    {{ $t("billing.specify_amt") }}
                                </div>
                                <div
                                    v-if="isLastPayment"
                                    class="px-5 py-1 text-left text-sm"
                                >
                                    <button
                                        @click="customAmount = false"
                                        class="flex font-bold text-medium"
                                    >
                                        <span
                                            v-if="customAmount === false"
                                            class="-ml-5 px-1"
                                            >✓</span
                                        >
                                        <div>
                                            {{
                                                bankInfo[bankInfo.length - 1]
                                                    .last_payment
                                            }}
                                            {{ $t("billing.last_payment") }}
                                        </div>
                                    </button>
                                    <label
                                        for="custom_amount"
                                        class="text-left pt-2 text-sm text-gray-500"
                                    >
                                        {{ $t("billing.other_amt") }}
                                    </label>
                                </div>
                                <div
                                    class="flex"
                                    :class="{ 'my-2': !isLastPayment }"
                                >
                                    <span
                                        v-if="customAmount === true"
                                        class="-ml-0 px-1"
                                        >✓</span
                                    >
                                    <div :class="{ 'pl-5': !customAmount }">
                                        HK$
                                    </div>
                                    <div class=" text-left w-full">
                                        <input
                                            id="custom_amount"
                                            @click="customAmount = true"
                                            class="px-3 w-full border-b focus:outline-none"
                                            ref="custom_amt"
                                            type="number"
                                            min="1"
                                            placeholder="E.g. 12,345.67"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div
                                v-if="isBankSelected"
                                class="bg-gray-100 rounded-xl px-4 mt-5 py-3 text-left"
                            >
                                <div class="font-bold mb-3">
                                    {{ $t("billing.step1") }}
                                </div>
                                <div>
                                    <span class="text-gray-500 mr-1">
                                        {{ $t("billing.transfer_amt") }}
                                    </span>
                                    {{
                                        this.bankInfo[this.bankInfo.length - 1]
                                            .currency
                                    }}{{
                                        parseFloat(this.topUp_amt).toFixed(2)
                                    }}
                                </div>
                                <div>
                                    <span class="text-gray-500 mr-1">
                                        {{ $t("billing.account_name") }}
                                    </span>
                                    {{
                                        this.bankInfo[this.bankInfo.length - 1]
                                            .acc_name
                                    }}
                                </div>
                                <div>
                                    <span class="text-gray-500 mr-1">
                                        {{ $t("billing.account_num") }}
                                    </span>
                                    {{
                                        this.bankInfo[this.bankInfo.length - 1]
                                            .acc_no
                                    }}
                                </div>
                                <div>
                                    <span class="text-gray-500 mr-1">
                                        {{ $t("billing.bank_name") }}
                                    </span>
                                    {{
                                        this.bankInfo[this.bankInfo.length - 1]
                                            .bank_name
                                    }}
                                </div>
                                <div>
                                    <span class="text-gray-500 mr-1">
                                        {{ $t("billing.bank_code") }}
                                    </span>
                                    {{
                                        this.bankInfo[this.bankInfo.length - 1]
                                            .bank_code
                                    }}
                                </div>
                                <div>
                                    <span class="text-gray-500 mr-1">
                                        {{ $t("billing.bank_swift") }}
                                    </span>
                                    {{
                                        this.bankInfo[this.bankInfo.length - 1]
                                            .bank_swift_code
                                    }}
                                </div>
                                <div>
                                    <span class="text-gray-500 mr-1">
                                        {{ $t("billing.bank_address") }}
                                    </span>
                                    {{
                                        this.bankInfo[this.bankInfo.length - 1]
                                            .bank_address
                                    }}
                                </div>
                                <br />
                                <div>
                                    <span class="text-gray-500 mr-1">
                                        {{ $t("billing.ref_no") }}
                                    </span>
                                    {{ this.refNum }}
                                </div>
                                <div class="text-xs text-gray-500 font-light">
                                    {{ $t("billing.ref_reminder") }}
                                </div>
                            </div>
                            <div
                                v-if="isBankSelected"
                                class="bg-gray-100 rounded-xl px-4 mt-5 py-3 text-left"
                            >
                                <div class="font-bold mb-3">
                                    {{ $t("billing.step2") }}
                                </div>
                                <div class="flex my-2">
                                    <label for="transfer_date" class="mr-3">
                                        {{ $t("billing.transfer_date") }}
                                    </label>
                                    <div class="flex-1 border-b">
                                        <input
                                            @keypress="dateFormatting($event)"
                                            id="transfer_date"
                                            ref="transfer_date"
                                            type="date"
                                            placeholder="YYYY-MM-DD"
                                            maxlength="10"
                                            class="bg-gray-100 px-3 w-full focus:outline-none"
                                        />
                                    </div>
                                </div>
                                <div class="flex my-2">
                                    <label for="payer_acc" class="mr-3">
                                        {{ $t("billing.payer_acc") }}
                                    </label>
                                    <div class="flex-1 border-b">
                                        <input
                                            id="payer_acc"
                                            ref="payer_acc"
                                            class="bg-gray-100 px-3 w-full focus:outline-none"
                                        />
                                    </div>
                                </div>
                                <div class="flex my-2 items-center">
                                    <label for="payer_acc" class="mr-3">
                                        Payment proof:
                                    </label>
                                    <a
                                        href="#"
                                        class="flex-1 bg-white py-10 my-2 rounded text-center"
                                        v-cloak
                                        id="advice"
                                        draggable="false"
                                        @drop.prevent="addFile"
                                        ondragstart="return false;"
                                        ondrop="return false;"
                                        @dragover.prevent
                                    >
                                        Drag and drop your bank slip here or
                                        <button
                                            class="underline focus:outline-none text-blue-600 mx-1"
                                            @click="$refs.advice.click()"
                                        >
                                            select a file to upload
                                        </button>
                                        (pdf, jpg or png)
                                        <ul>
                                            <li
                                                v-for="(file, index) in files"
                                                :key="index"
                                            >
                                                {{ file.name }} ({{
                                                    file.size / 1000000
                                                }}
                                                MB)
                                            </li>
                                        </ul>
                                    </a>
                                </div>
                            </div>
                            <div class="mt-3 py-3 text-right">
                                <button
                                    v-if="!isBankSelected"
                                    @click="checkAmount"
                                    class="bg-gray-800 border border-transparent rounded-md shadow-sm py-2 px-20 inline-flex justify-center text-sm font-medium text-white hover:bg-gray-900 focus:outline-none"
                                >
                                    {{ $t("billing.pay") }}
                                </button>
                                <button
                                    v-if="isBankSelected"
                                    @click="sendTransferReq"
                                    :disabled="sendButton"
                                    :class="{ 'opacity-30': sendButton }"
                                    class="bg-gray-800 border border-transparent rounded-md shadow-sm py-2 px-20 inline-flex justify-center text-sm font-medium text-white hover:bg-gray-900 focus:outline-none transition duration-500 ease-in-out"
                                >
                                    {{ $t("billing.send") }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <input
                type="file"
                ref="advice"
                style="display:none"
                accept="image/png, image/jpeg, .pdf,"
                @change="addFile()"
            />
        </div>
        <div v-if="isLoading" class="flex justify-center mt-20 items-center">
            <div class="w-1/5">
                <Spinner />
            </div>
        </div>
    </div>
</template>

<script>
import { jsPDF } from "jspdf"
import Spinner from "@/components/Spinner"
import S3 from "aws-sdk/clients/s3"
import AWS from "aws-sdk"

export default {
    name: "Topup",
    components: { Spinner },
    data() {
        return {
            data: {},
            isLoading: true,
            isInitial: true,
            addCard: false,
            invalidNum: false,
            toggleModal: false,
            thresholdButton: false,
            newThreshold: 0,
            makeTopUpButton: false,
            sendButton: false,
            payButton: false,
            activeItem: null,
            isLastPayment: false,
            customAmount: false,
            bankInfo: {},
            attach_key: "",
            pdfData: {},
            files: [],
            refNum: "",
            randomNum: 0,
            paymentType: 1,
            isBankSelected: false,
            topUp_amt: 0,
            disableSwitch: false,
            cardID: -1,
            stripe: "",
            elements: "",
            card: "",
            token: ""
        }
    },
    props: {
        user: { required: false, type: Object },
        enterprise: { required: false }
    },
    watch: {
        enterprise(to, from) {
            if (this.user.role === 1) {
                this.getInfo()
            }
        }
    },
    methods: {
        includeStripe(URL, callback) {
            let documentTag = document,
                tag = "script",
                object = documentTag.createElement(tag),
                scriptTag = documentTag.getElementsByTagName(tag)[0]
            object.src = "//" + URL
            if (callback) {
                object.addEventListener(
                    "load",
                    function(e) {
                        callback(null, e)
                    },
                    false
                )
            }
            scriptTag.parentNode.insertBefore(object, scriptTag)
        },
        configureStripe() {
            // eslint-disable-next-line no-undef
            this.stripe = Stripe(process.env.VUE_APP_STRIPE_KEY)

            this.elements = this.stripe.elements()
            this.card = this.elements.create("cardNumber", {
                placeholder: "XXXX XXXX XXXX XXXX"
            })
            // this.card = this.elements.create("card", {
            //     hidePostalCode: true,
            //     placeholder: "0000 0000 0000 0000",
            //     style: {
            //         base: {
            //             iconColor: "#666EE8",
            //             color: "#31325F",
            //             lineHeight: "40px",
            //             fontWeight: 300,
            //             fontFamily: "Helvetica Neue",
            //             fontSize: "15px",
            //
            //             "::placeholder": {
            //                 color: "#CFD7E0"
            //             }
            //         }
            //     }
            // })
            const cardExpiryElement = this.elements.create("cardExpiry")
            const cardCvcElement = this.elements.create("cardCvc")
            this.card.mount("#card-number")
            cardExpiryElement.mount("#card-expiry")
            cardCvcElement.mount("#card-cvc")
        },
        async addCardBtn() {
            this.addCard = true
            if (this.isInitial) {
                await this.includeStripe(
                    "js.stripe.com/v3/",
                    function() {
                        this.configureStripe()
                    }.bind(this)
                )
                this.isInitial = false
            } else await this.configureStripe()
        },
        discardCard() {
            if (this.bankInfo.length > 1) {
                this.addCard = false
            } else {
                this.elements.getElement("cardNumber").clear()
                this.elements.getElement("cardExpiry").clear()
                this.elements.getElement("cardCvc").clear()
            }
            this.$refs.card_name.value = ""
        },
        async removeCard(cardID) {
            const token = await this.$auth.getTokenSilently()
            const data = {
                user_id: this.$auth.user.value.sub,
                card_id: cardID
            }
            const url =
                process.env.VUE_APP_API_BASE_URL +
                `/payment/card/disable?lang=${this.$i18n.locale}`
            this.$axios
                .put(url, data, {
                    headers: {
                        Authorization: `Bearer ${token}` // send the access token through the 'Authorization' header
                    }
                })
                .then(res => {
                    this.bankTransferInfo()
                })
        },
        async saveCard() {
            const token = await this.$auth.getTokenSilently()
            this.stripe
                .createToken(this.card, { name: this.$refs.card_name.value })
                .then(res => {
                    if (res.token) {
                        const data = {
                            user_id: this.$auth.user.value.sub,
                            stripe_token: res.token.id
                        }
                        const url =
                            process.env.VUE_APP_API_BASE_URL +
                            `/payment/card/add?lang=${this.$i18n.locale}`
                        this.$axios
                            .post(url, data, {
                                headers: {
                                    Authorization: `Bearer ${token}` // send the access token through the 'Authorization' header
                                }
                            })
                            .then(res => {
                                if (res.data.Success) {
                                    alert("Success")
                                    this.bankTransferInfo()
                                    this.addCard = false
                                    this.$refs.card_name.value = ""
                                } else alert(res.data.Data.message)
                            })
                    } else alert("Invalid credit card info, please try again.")
                })
            //
            // const res2 = await this.stripe.createSource(this.card, {
            //     type: "card",
            //     owner: {
            //         name: this.$refs.card_name.value
            //     }
            // })
            // console.log(res2)
        },
        async getInfo() {
            const token = await this.$auth.getTokenSilently()
            let url = ""
            if (this.user.role === 1) {
                url =
                    process.env.VUE_APP_API_BASE_URL +
                    `/credit/dashboard?user_id=${this.$auth.user.value.sub}&lang=${this.$i18n.locale}&enterprise_id=${this.enterprise}`
            } else
                url =
                    process.env.VUE_APP_API_BASE_URL +
                    `/credit/dashboard?user_id=${this.$auth.user.value.sub}&lang=${this.$i18n.locale}`
            this.$axios
                .get(url, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                .then(response => {
                    this.data = response.data.Data
                    this.newThreshold = this.data.amt_alert
                    setTimeout(() => {
                        this.isLoading = false
                    }, 500)
                })
        },
        async updateThreshold(amt) {
            this.thresholdButton = true
            const token = await this.$auth.getTokenSilently()
            if (isNaN(amt) === false && amt !== "") {
                const url =
                    process.env.VUE_APP_API_BASE_URL +
                    `/credit/notify/amount?lang=${this.$i18n.locale}`
                this.$axios
                    .put(
                        url,
                        { user_id: this.$auth.user.value.sub, amount: amt },
                        {
                            headers: {
                                Authorization: `Bearer ${token}`
                            }
                        }
                    )
                    .then(res => {
                        if (res.data.Success) {
                            alert("Success")
                            this.invalidNum = false
                            this.getInfo()
                        } else alert("Failed to update, please try again.")
                    })
            } else this.invalidNum = true
            setTimeout(() => {
                this.thresholdButton = false
            }, 1200)
        },
        async makeTopUp() {
            this.makeTopUpButton = true
            this.toggleModal = true
            setTimeout(() => {
                this.makeTopUpButton = false
            }, 1200)
            // await this.bankTransferInfo()
        },
        closeModal() {
            this.files = []
            this.toggleModal = false
            this.disableSwitch = false
            this.isBankSelected = false
        },
        addFile(e) {
            if (e) {
                if (e.dataTransfer.files.length === 1) {
                    if (e.dataTransfer.items[0].kind === "file") {
                        let file = e.dataTransfer.items[0].getAsFile()
                        let droppedFiles = e.dataTransfer.files
                        if (!droppedFiles)
                            return // this tip, convert FileList to array, credit: https://www.smashingmagazine.com/2018/01/drag-drop-file-uploader-vanilla-js/
                        ;[...droppedFiles].forEach(f => {
                            this.files = []
                            this.files.push(f)
                        })
                    } else alert("This is not a valid file, please try again.")
                } else {
                    alert("Please select only 1 file.")
                }
            } else {
                let file = this.$refs.advice.files[0]
                let droppedFiles = this.$refs.advice.files[0]
                this.files = []
                this.files.push(droppedFiles)
            }
        },
        generateRan(length) {
            return window
                .btoa(
                    Array.from(
                        window.crypto.getRandomValues(
                            new Uint8Array(length * 2)
                        )
                    )
                        .map(b => String.fromCharCode(b))
                        .join("")
                )
                .replace(/[+/]/g, "")
                .substring(0, length)
        },
        async handleFile() {
            // let files = this.files[0]
            // if (!files.length) {
            //     return alert("Please choose a file to upload first.")
            // }

            let f = this.files[0]
            if (f) {
                if (f.size <= 5000000) {
                    if (
                        f.name.split(".").pop() === "jpg" ||
                        f.name.split(".").pop() === "pdf" ||
                        f.name.split(".").pop() === "png"
                    ) {
                        let fileName = ""
                        if (process.env.VUE_APP_DEV) {
                            fileName =
                                process.env.VUE_APP_DEV +
                                `_${this.refNum}_` +
                                this.generateRan(5) +
                                "." +
                                f.name.split(".").pop()
                        } else {
                            fileName =
                                `${this.refNum}_` +
                                this.generateRan(5) +
                                "." +
                                f.name.split(".").pop()
                        }
                        const s3 = new S3({
                            correctClockSkew: true,
                            endpoint: "https://s3.us-west-1.wasabisys.com", //use appropriate endpoint as per region of the bucket
                            accessKeyId: "Q2NTPTVQPCI2E94ELXBZ",
                            secretAccessKey:
                                "ofZXIoKAWw6mLSmcLjeJlUPyyUdk6LRDhYmAYGcr",
                            region: "us-west-1"
                        })

                        let params = {
                            Bucket: "remittance-advice",
                            Key: fileName,
                            Body: f
                        }

                        let putObjectPromise = s3.putObject(params).promise()

                        await putObjectPromise
                            .then(res => {
                                this.attach_key = fileName
                            })
                            .catch(function(err) {
                                alert("Upload failed, please try again.")
                            })
                    } else alert("Wrong file type. Please try again.")
                } else
                    alert(
                        "Your file is too big. Please upload a file that's smaller than 5MB."
                    )
            } else alert("Please attach your payment proof.")
        },
        isNumber(e) {
            let char = e.key
            if (/^[0-9]+$/.test(char)) return true
            else e.preventDefault()
        },
        selectItem(i, cardID) {
            this.activeItem = i
            this.paymentType = 1
            this.cardID = cardID
        },
        bank() {
            this.activeItem = null
            this.paymentType = 2
            // console.log(this.paymentType)
        },
        async getInvoiceContent(id) {
            const token = await this.$auth.getTokenSilently()
            const url =
                process.env.VUE_APP_API_BASE_URL +
                `/credit/topup/invoice?user_id=${this.$auth.user.value.sub}&lang=${this.$i18n.locale}&id=${id}`
            this.$axios
                .get(url, {
                    headers: {
                        Authorization: `Bearer ${token}` // send the access token through the 'Authorization' header
                    }
                })
                .then(res => {
                    this.pdfData = res.data.Data
                    this.createPDF()
                })
        },
        async checkAmount() {
            this.disableSwitch = true
            if (
                this.$refs.custom_amt.value !== "" &&
                this.customAmount &&
                !isNaN(this.$refs.custom_amt.value) &&
                this.$refs.custom_amt.value > 0
            ) {
                await this.getRefNum()
                this.topUp_amt = this.$refs.custom_amt.value
            } else if (!this.customAmount) {
                await this.getRefNum()
                this.topUp_amt = this.bankInfo.list[0].last_payment
            } else alert("Invalid amount, please try again.")
        },
        async getRefNum() {
            const token = await this.$auth.getTokenSilently()
            const url =
                process.env.VUE_APP_API_BASE_URL +
                `/credit/bank/transfer/reference?user_id=${this.$auth.user.value.sub}&lang=${this.$i18n.locale}`
            this.$axios
                .get(url, {
                    headers: {
                        Authorization: `Bearer ${token}` // send the access token through the 'Authorization' header
                    }
                })
                .then(res => {
                    let dialogMsg = ""
                    this.refNum = res.data.Data.reference_no
                    if (this.paymentType === 2) {
                        this.isBankSelected = true
                    } else if (this.paymentType === 1) {
                        if (this.$i18n.locale === "en") {
                            dialogMsg = `Here are the payment details you are submitting:\nAmount: ${
                                this.topUp_amt
                            }\nPay with: ${
                                this.bankInfo[this.activeItem].brand
                            } ….${this.bankInfo[this.activeItem].last4}`
                        } else {
                            dialogMsg = `以下是您的付款詳情:\n總數： ${
                                this.topUp_amt
                            }\n付款方式: ${
                                this.bankInfo[this.activeItem].brand
                            } ….${this.bankInfo[this.activeItem].last4}`
                        }
                        if (confirm(dialogMsg)) {
                            this.stripePayment()
                        }
                    }
                })
        },

        async stripePayment() {
            const token = await this.$auth.getTokenSilently()
            const url =
                process.env.VUE_APP_API_BASE_URL +
                `/credit/topup/charge?lang=${this.$i18n.locale}`
            const data = {
                user_id: this.$auth.user.value.sub,
                card_id: this.cardID,
                amount: this.topUp_amt,
                reference_no: this.refNum
            }
            this.$axios
                .post(url, data, {
                    headers: {
                        Authorization: `Bearer ${token}` // send the access token through the 'Authorization' header
                    }
                })
                .then(res => {
                    if (res.data.Success) {
                        alert("Success")
                        this.getInfo()
                        this.closeModal()
                    } else {
                        this.closeModal()
                        alert(res.data.Data.message)
                    }
                })
        },
        async bankTransferInfo() {
            const token = await this.$auth.getTokenSilently()
            const url =
                process.env.VUE_APP_API_BASE_URL +
                `/credit/topup/method/list?user_id=${this.$auth.user.value.sub}&lang=${this.$i18n.locale}`
            this.$axios
                .get(url, {
                    headers: {
                        Authorization: `Bearer ${token}` // send the access token through the 'Authorization' header
                    }
                })
                .then(res => {
                    this.bankInfo = res.data.Data
                    // this.toggleModal = true
                    if (
                        this.bankInfo[0].type === "bank_transfer" &&
                        this.user.role !== 1
                    ) {
                        this.paymentType = 2
                        setTimeout(() => {
                            this.addCardBtn()
                        }, 1000)
                    } else this.paymentType = 1
                    if (
                        this.bankInfo[this.bankInfo.length - 1].last_payment !==
                        ""
                    ) {
                        this.isLastPayment = true
                    } else this.customAmount = true
                })
        },
        async sendTransferReq() {
            this.sendButton = true
            let timestamp = Date.parse(this.$refs.transfer_date.value)

            if (isNaN(timestamp) === false) {
                if (
                    this.$refs.payer_acc.value !== "" &&
                    this.$refs.payer_acc.value
                ) {
                    this.attach_key = ""
                    await this.handleFile()
                    if (this.attach_key) {
                        const token = await this.$auth.getTokenSilently()
                        const data = {
                            user_id: this.$auth.user.value.sub,
                            transfer_date: this.$refs.transfer_date.value,
                            payer_acc: this.$refs.payer_acc.value,
                            amount: this.topUp_amt,
                            reference_no: this.refNum,
                            attach_key: this.attach_key
                        }
                        const url =
                            process.env.VUE_APP_API_BASE_URL +
                            `/credit/bank/transfer/notice?lang=${this.$i18n.locale}`
                        this.$axios
                            .post(url, data, {
                                headers: {
                                    Authorization: `Bearer ${token}` // send the access token through the 'Authorization' header
                                }
                            })
                            .then(r => {
                                this.getInfo()
                                this.closeModal()
                            })
                    }
                } else alert("Please put in payer account")
            } else alert("Invalid date")
            setTimeout(() => {
                this.sendButton = false
            }, 1000)
        },
        dateFormatting(e) {
            this.isNumber(e)
            if (
                this.$refs.transfer_date.value.length === 4 ||
                this.$refs.transfer_date.value.length === 7
            ) {
                this.$refs.transfer_date.value += "-"
            }
        },
        createPDF() {
            const logo = require("@/assets/flow_s.png")
            let pdfName =
                "receipt_20" +
                this.pdfData.payment_date.split(" ")[0].split("/")[2] +
                "_" +
                this.pdfData.payment_date.split("/")[1] +
                "_" +
                this.pdfData.payment_date.split("/")[0]
            let doc = new jsPDF({ format: "a4" })
            let imgLogo = new Image()
            imgLogo.src = logo
            doc.addImage(imgLogo, "PNG", 20, 15, 32, 15)
            doc.setFont("arial", "bold")
            doc.setFontSize(26)
            doc.text("Payment Receipt", 115, 30)

            doc.setFontSize(9)
            doc.setFont("arial", "normal")

            doc.text(
                "Flow Entertainment Limited\n\nUnit 2202, 22/F\n" +
                    "Causeway Bay Plaza I\n" +
                    "489 Hennessy Road\n" +
                    "Causeway Bay, Hong Kong",
                20,
                60
            )
            doc.text(
                this.pdfData.payment_date +
                    "\n" +
                    this.pdfData.payment_method +
                    "\n" +
                    this.pdfData.transaction_id +
                    "\n" +
                    this.pdfData.account_id,
                150,
                60
            )

            doc.text(
                `${this.pdfData.enterprise}\n` +
                    this.pdfData.address[0] +
                    " \n" +
                    this.pdfData.address[1] +
                    "\n" +
                    "\n" +
                    this.pdfData.name +
                    "\n",
                50,
                90
            )

            doc.text("Company credits top-up", 25, 136)
            doc.text(this.pdfData.amount, 195, 136, null, null, "right")

            doc.setFont("arial", "bold")
            doc.text(
                "Payment Date:\n" +
                    "Payment Method:\n" +
                    "Transaction ID:\n" +
                    "Account ID:",
                115,
                60
            )

            doc.text("To:\n" + "Attn:\n" + "\n" + "\n" + "Attn:\n", 20, 90)

            doc.setFillColor(225, 250, 250)
            doc.rect(20, 120, 180, 10, "FD")
            // doc.cell(20, 120, 170, 10, "Description")
            doc.text("Description", 25, 126)
            doc.text("Amount", 195, 126, null, null, "right")

            doc.rect(20, 130, 180, 10)

            doc.setFontSize(11)
            doc.text(
                "Paid\n" + "\n" + this.pdfData.amount,
                200,
                90,
                null,
                null,
                "right"
            )

            doc.setFont("arial", "regular")
            doc.text("1", 200, 285)

            doc.line(20, 160, 200, 160)

            doc.save(pdfName + ".pdf")
        }
    },
    mounted() {
        this.getInfo()
        this.bankTransferInfo()
    }
}
</script>

<style scoped></style>
